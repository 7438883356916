import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";


export default function Faq() {
  const [openFaq, setopenFaq] = useState(0);

  const handleFaq = (index) => {
    setopenFaq(openFaq === index ? null : index);
  }

  const faqData = [
    {
      question: "What is D5art?",
      answer: "D5art is a Web3 platform designed to bridge the gap between Education and Employment through its Edu to Emp concept. It combines a Recruitment Management System (RMS) and a Learning Management System (LMS) to offer job seekers a streamlined path from skill enhancement to verified employment opportunities. The platform enables users to improve their skills, validate them using NFTs, and connect directly with recruiters. Recruiters benefit from an unbiased hiring process where they purchase NFTs representing verified skills, reducing the need for traditional job postings."
    },
    {
      question: "How does D5art’s Recruitment Management System (RMS) work?",
      answer: "D5art's RMS eliminates traditional job posting by offering recruiters the ability to directly purchase NFTs that represent job seekers with verified skills. The platform ensures unbiased hiring as recruiters do not have access to personal details. Job seekers are verified through an assessment process, and NFTs represent their skills and qualifications."
    },
    {
      question: "Who are validators and what do they do?",
      answer: "Validators are industry experts who create question sets and assessments for job seekers on D5art. They review and verify the answers submitted by job seekers. If a job seeker successfully completes the assessment, the validator validates the respective skill on the job seeker’s profile. Validators are compensated for each verification they perform."
    },
    {
      question: "How does the NFT-based recruitment process work?",
      answer: "In D5art, job seekers are represented by NFTs that highlight their skills and qualifications. Once their skills are validated, the NFTs become available for purchase on the marketplace. Recruiters can directly buy these NFTs to hire individuals, streamlining the recruitment process and removing unnecessary steps like job posting."
    },
    {
      question: "How does skill verification work on D5art?",
      answer: "Job seekers can take assessments created by validators. After completing an assessment, a validator reviews their answers. If the job seeker qualifies, their skills are verified, and this verification is added to their profile as a non-fungible token (NFT). The verified skill becomes a proof of competency on their CV."
    },
    {
      question: "How is D5art’s recruitment process different from traditional hiring?",
      answer: "D5art simplifies the recruitment process by enabling recruiters to buy validated job seeker profiles (NFTs) directly from a marketplace. It eliminates biases by hiding personal details, focuses on verified skills, and reduces the time spent on screening candidates through traditional means."
    },
    {
      question: "How does the platform ensure unbiased hiring?",
      answer: "Recruiters on D5art only have access to the job seeker’s skills and qualifications represented through NFTs. Personal details like name, gender, age, and location are not displayed, ensuring that hiring decisions are solely based on verified skills."
    },
    {
      question: "How can job seekers enhance their skills using D5art?",
      answer: "Job seekers can access various courses and training materials on the LMS section of D5art. These are designed to help users build or upgrade their skills, particularly in Web3-related areas. Upon completion of assessments, their new skills can be verified and added to their profile."
    },
    {
      question: "Is D5art exclusive to Web3-related jobs and skills?",
      answer: "While D5art is designed to serve the Web3 industry, its structure allows for expansion into other industries. The initial focus is on providing Web3-specific resources, but other sectors may be incorporated in the future."
    }
  ];


  return (
    <div className="font-bricolagegrotesque text-white bg-[#191919]" style={{ backgroundImage: "url('./FABC.png')" }}>
      <Header />

      <div className="main-container">
        <div className=" pb-7">
          <p className="text-3xl font-bold text-center">FAQ</p>
        </div>
        <div className="w-[70%] mx-auto pb-10">
          {faqData.map((faq, index) => (
            <div key={index} className="border-b py-5">
              <p className="font-bold text-xl flex items-center justify-between" >
                <span onClick={() => handleFaq(index)} className='cursor-pointer'>{faq.question}</span>
                <span>
                  <i
                    className={`fa-solid cursor-pointer duration-500 ${openFaq === index ? 'fa-minus rotate-180' : 'fa-plus'}`}
                    onClick={() => handleFaq(index)}>
                  </i>
                </span>
              </p>
              <p className={`text-sm overflow-hidden duration-1000 ${openFaq === index ? 'max-h-[400px] pt-3' : 'max-h-0'}`}>
                {faq.answer}
              </p>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}