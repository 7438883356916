import React from "react";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Header";
import { NavLink, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Footer from "./Footer";

export default function Landing() {
  const [moveImage, setMoveImage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch("https://edu.d5art.com/api/Allcourses");
        const data = await response.json();
        const filteredCourses = data.filter(
          (course) => course.status === "enabled"
        );
        setCourses(filteredCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, []);

  const truncateDescription = (description) => {
    const words = description.split(" ");
    const truncatedDescription = words.slice(0, 20).join(" ");
    const showMore = words.length > 20;
    return showMore ? `${truncatedDescription}.....` : truncatedDescription;
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleSubmitChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate all fields
    const { firstName, lastName, email, phone, message } = formData;
    if (!firstName || !lastName || !email || !phone || !message) {
      toast.error("All fields are required");
      return;
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    toast.success("Submitted successfully");

    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  // const imageList = [
  //     { src: './assets/image 31.png', className: 'md:mt-10 translate-y-2' },
  //     { src: './assets/image 32.png', className: 'translate-y-1 md:translate-y-3' },
  //     { src: './assets/image 33.png', className: 'w-9/12 md:-translate-y-2' },
  //     { src: './assets/image 34.png', className: '-translate-y-2' },
  //     { src: './assets/image 31.png', className: 'md:mt-10 translate-y-2' },
  //     { src: './assets/image 32.png', className: 'translate-y-2 md:translate-y-3' },
  //     { src: './assets/image 33.png', className: 'w-9/12 md:-translate-y-2' },
  //     { src: './assets/image 34.png', className: '-translate-y-2' }
  // ];

  const imageList = [
    { src: "./assets/S1.png" },
    { src: "./assets/S2.png" },
    { src: "./assets/S3.png" },
    { src: "./assets/S4.png" },
    { src: "./assets/S5.png" },
    { src: "./assets/S6.png" },
    { src: "./assets/S7.png" },
    { src: "./assets/S8.png" },
    { src: "./assets/S9.png" },
    { src: "./assets/s10.png" },
    { src: "./assets/s11.png" },
    { src: "./assets/s12.png" },
    { src: "./assets/s13.png" },
    { src: "./assets/s14.png" },
    { src: "./assets/s15.png" },
  ];

  const handleSearch = () => {
    const url = `https://edu.d5art.com/courses?search=${encodeURIComponent(
      searchTerm
    )}`;
    window.location.href = url;
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const moveInterval = setInterval(() => {
      setMoveImage((prevMove) => !prevMove);
    }, 1500); // Adjust the interval as needed

    return () => {
      clearInterval(moveInterval);
    };
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 734,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 7000);

    return () => clearInterval(timer);
  }, []);

  const handleBlog = (url) => {
    const fullUrl = url.startsWith("http") ? url : `https://${url}`;
    window.open(fullUrl, "_blank");
  };

  const teamMembers = [
    {
      name: "Sakthi Visakan Rajaguru",
      role: "CEO",
      imageSrc: "./assets/sakthi_prev_ui 1.png",
    },
    {
      name: "Khalid Al Mansouri",
      role: "BOARD ADVISOR",
      imageSrc: "./assets/Khalid_prev_ui 1.png",
    },
    {
      name: "Anup Kumar",
      role: "CTO",
      imageSrc: "./assets/Anup BM_prev_ui 1.png",
    },
    {
      name: "Annamalai Palaniappan",
      role: "COO",
      imageSrc: "./assets/Annamalai BM_prev_ui 1.png",
    },
    {
      name: "Gaurav Sharma",
      role: "CFO",
      imageSrc: "./assets/Gauvrav_prev_ui 1.png",
    },
    {
      name: "Alun Richards",
      role: "BOARD ADVISOR",
      imageSrc: "./assets/alun-_prev_ui 1.png",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? teamMembers.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === teamMembers.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div>
      <div
        className="bg-[#1A1919] font-poppins min-h-screen "
        style={{ backgroundImage: "url('./assets/FABC.png')" }}
      >
        <Header />
        <Toaster />
        <div className="Section-1">
          <div className="w-full md:w-[100%] pt-14 px-10">
            <div className="flex justify-around h-full">
              <div className="h-full flex items-center translate-y-24">
                <img
                  style={{
                    transition: "transform 0.9s ",
                    transform: moveImage ? "translateY(25px)" : "translateY(0)",
                  }}
                  className=""
                  src="./assets/image 100.png"
                  alt="circle1"
                ></img>
              </div>
              <div
                className=""
                style={{
                  transition: "transform 2s ",
                  transform: moveImage ? "translateY(45px)" : "translateY(0)",
                }}
              >
                <img src="./assets/Ellipse 428.png" alt="circle2"></img>
              </div>
              <div
                style={{
                  transition: "transform 0.1s ",
                  transform: moveImage ? "translateY(20px)" : "translateY(0)",
                }}
              >
                <img src="./assets/Ellipse 429.png" alt="circle2"></img>
              </div>
            </div>
            <div>
              <p className="text-white text-center font-bold tracking-widest text-3xl lg:text-4xl ">
              Unlock the Gate way to your{" "}
                <span
                  style={{
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    backgroundImage:
                      "linear-gradient(to right, #00768B,#0E2DA7)",
                  }}
                >
                  WEB 3{" "}
                </span>
                Destiny
                <br></br>{" "}
                {/* <span className="text-2xl"> Your Knowledge, Your Asset.</span>{" "} */}
              </p>
            </div>
          </div>
          <div className="flex justify-center mt-10">
            <p className="w-[90%] md:w-[40%] font-museomoderno text-center text-white font-musemo text-lg">
       Forge your path in the Web3 universe, where your skills are your currency and your patential knows no bounds. Discover a realm of limitless career opportunites
            </p>
          </div>

          <div className="flex flex-col lg:flex-row  gap-5 md:gap-0">
            <div
              className="lg:w-[30%] flex justify-center bg-cover bg-center"
              style={{ backgroundImage: 'url("./assets/Ellipse 3 (1).png")' }}
            >
              <img className="w-[75%]" src="./assets/avatar12.png"></img>
            </div>
            <div
              className="lg:w-[40%] grid content-around bg-cover bg-center"
              style={{ backgroundImage: 'url("./assets/Ellipse 1 (1).png")' }}
            >
              <div className="flex justify-center text-2xl text-center lg:text-start font-museomoderno mt-5 md:mt-0 p-5">
                <div className="w-[40%]">
                  <p className="text-white ">22,000+</p>
                  <p className="text-white font-musemo text-base">Enrolled Users</p>
                </div>
                <div className="w-[40%] flex justify-center">
                  <div>
                    <p className="text-white">50+</p>
                    <p className="text-white font-musemo text-base">
                    Global  Partnership
                    </p>
                  </div>
                </div>
                <div className="w-[40%] lg:flex lg:justify-end">
                  <div>
                    <p className="text-white">100+</p>
                    <p className="text-white font-musemo text-base">Skill Passports</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="lg:w-[30%] flex justify-center bg-cover bg-no-repeat bg-center"
              style={{ backgroundImage: 'url("./assets/Ellipse 6.png")' }}
            >
              <img className="w-[70%]" src="./assets/image 51.png"></img>
            </div>
          </div>
          <div>
            <div className="mt-5">
              <p className="text-white font-museomoderno text-center text-2xl ">
                Trusted By
              </p>
            </div>
            <div className="flex justify-center px-3 lg:px-0">
              <Slider
                className="mt-3 w-[90%]"
                {...settings}
                prevArrow={<></>}
                nextArrow={<></>}
              >
                {imageList.map((image, index) => (
                  <div key={index} className="grid content-center h-[150px]">
                    <img
                      className="m-auto p-3 md:p-2"
                      src={image.src}
                      alt={`slider-image-${index}`}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>


        <div className="Section-2">
          <div className="mt-10">
            <p className="text-[#FFBA00] font-museomoderno font-bold text-center text-3xl">
            Forge your   Virtual Identity
            </p>
          </div>
          <div className="mt-5 flex flex-col lg:flex-row pb-10 ">
            <div className="lg:w-[50%] flex font-nerko text-3xl">
              <div
                className="w-[30%] mt-20 bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: 'url("./assets/Ellipse 7.png")' }}
              >
                <img src="./assets/image 49.png"></img>
                <p className="font-musemo text-gray-500 text-center">inam</p>
              </div>
              <div
                className="w-[30%] bg-contain bg-center bg-no-repeat"
                style={{ backgroundImage: 'url("./assets/Ellipse 7.png")' }}
              >
                <img src="./assets/image 50 (1).png"></img>
                <p className="font-musemo text-gray-500 text-center">Puna</p>
              </div>
              <div
                className="w-[30%] mt-20 bg-contain bg-right bg-no-repeat"
                style={{ backgroundImage: 'url("./assets/Ellipse 7.png")' }}
              >
                <img src="./assets/image 98.png"></img>
                <p className="font-musemo text-gray-500 text-center">Edith</p>
              </div>
            </div>
            <div className="lg:w-[50%] px-3 lg:px-0">
              <div className="w-full md:w-[100%] ">
                <div className="flex justify-around h-full">
                  <div
                    className=""
                    style={{
                      transition: "transform 2s ",
                      transform: moveImage
                        ? "translateY(45px)"
                        : "translateY(0)",
                    }}
                  >
                    <img src="./assets/Ellipse 428.png" alt="circle2"></img>
                  </div>
                  <div
                    style={{
                      transition: "transform 0.5s ",
                      transform: moveImage
                        ? "translateY(25px)"
                        : "translateY(0)",
                    }}
                  >
                    <img src="./assets/Ellipse 429.png" alt="circle2"></img>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="flex px-5 mt-10">
                <p className="text-lg text-white md:w-[80%] leading-8">
                "Shatter traditional hiring barriers with your {" "}
                <span className="text-transparent bg-clip-text font-bold bg-[#FFBA00]">
                D5art ID.
                </span>
                 Let your skills guide you as your {" "}
                 <span className="text-transparent bg-clip-text bg-gradient-to-r font-bold  from-[#FFBA00] to-[#0EA4A9]">
                  D5art Passport reveals your true potential in the Web3 realm.{" "}
                  </span>
                   Embark on a Skill Quest to acquire new abilities and validate your expertise, where unbiased hiring ensures that only your talents shine, unlocking paths to opportunities based on merit."
                </p>
              </div>

              <div className="w-full md:w-[100%] relative">
                <div className="flex justify-center md:justify-between px-5 h-full">
                  <NavLink to="https://ex.d5art.com/SeekerRegistration">
                    <div className="flex justify-start mt-8">
                      <button className="flex pt-3 pb-1 px-2 bg-[#1A1919] z-20 rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white">
                        <button className="p-2 rounded-full translate-y-0.5 group-hover:translate-x-[119px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5"></button>
                        <p className="-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out">
                          Create
                        </p>
                        <button className="ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                      </button>
                    </div>
                  </NavLink>
                  <div
                    className="absolute md:relative z-0"
                    style={{
                      transition: "transform 0.1s ",
                      transform: moveImage
                        ? "translateY(20px)"
                        : "translateY(0)",
                    }}
                  >
                    <img src="./assets/Ellipse 429.png" alt="circle2"></img>
                  </div>
                  <div
                    className="hidden md:block"
                    style={{
                      transition: "transform 2s ",
                      transform: moveImage
                        ? "translateY(45px)"
                        : "translateY(0)",
                    }}
                  >
                    <img src="./assets/Ellipse 428.png" alt="circle2"></img>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="w-full md:w-[100%] mt-2">
                <div className="flex justify-between px-5 h-full">
                  <div
                    style={{
                      transition: "transform 1s ",
                      transform: moveImage
                        ? "translateY(20px)"
                        : "translateY(0)",
                    }}
                  >
                    <img src="./assets/image 100.png" alt="circle2"></img>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>


        <div className="Section-3">
          <div className="w-full md:w-[100%] md:pt-14 px-10">
            <div className="w-[42%] flex justify-end  h-full">
              <div
                className=""
                style={{
                  transition: "transform 5s ",
                  transform: moveImage ? "translateY(35px)" : "translateY(0)",
                }}
              >
                <img src="./assets/image 100.png" alt="circle2"></img>
              </div>
            </div>
            <div>
              <p className=" text-[#FFBA00] font-museomoderno text-center font-bold text-3xl">
D5art Passport
              </p>
            </div>
            <div className="w-[75%] mx-auto flex justify-end h-full">
              <div
                className=""
                style={{
                  transition: "transform 5s ",
                  transform: moveImage
                    ? "translateY(35px) translateX(35px)"
                    : "translateY(0) translateX(0)",
                }}
              >
                <img src="./assets/Ellipse 429.png" alt="circle2"></img>
              </div>
            </div>
            <p className="text-[#FFFFFF]/75 mx-auto text-center md:w-[80%] font-museomoderno">


            In this realm, your D5art ID becomes your digital fingerprint, an indelible mark of your capabilities and accomplishments. 
             <br />
             Every milestone, every verified skill, and every achievement is etched into your D5art Passport, guiding you towards your next career leap.

            </p>

            <div className="w-[75%] mx-auto  h-full">
              <div
                className="-translate-y-20"
                style={{
                  transition: "transform 5s ",
                  transform: moveImage ? "translateY(25px) " : "translateY(0) ",
                }}
              >
                <img src="./assets/Ellipse 428.png" alt="circle2"></img>
              </div>
            </div>
            <div>
              <img src="/assets/nftcv.png" className="mx-auto"></img>
              <div className="flex justify-center">
                <NavLink
                  className="flex justify-center relative"
                  to="https://ex.d5art.com"
                >
                  <button className="absolute md:-translate-y-16 text-white bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]">
                    <button className="flex pt-3 pb-1 font-play px-2.5 bg-[#1A1919] rounded-full group ">
                      <button className="p-2 rounded-full translate-y-1 group-hover:translate-x-[111px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>{" "}
                      <p className="mx-3 -translate-y-0.75 group-hover:-translate-x-4 trasnform-transition duration-500 ease-in-out text-lg">
                        Explore
                      </p>{" "}
                      <button className=" p-4 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                    </button>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="Section-4">
          <div className="mt-10">
            <p className="text-[#FFBA00] font-museomoderno font-bold text-center text-3xl">
    Skill Realm     
       </p>
          </div>
          <div className="mt-10 w-[90%] mx-auto flex flex-col lg:flex-row justify-around pb-10 ">
          <div className="lg:w-[60%] px-3 lg:px-0  bg-cover bg-center bg-no-repeat "
          style={{ backgroundImage: 'url("./assets/Ellipse 7.png")' }}

>
              <div className="w-full md:w-[100%] ">
                <div className="flex justify-around h-full">
                  <div
                    className=""
                    style={{
                      transition: "transform 2s ",
                      transform: moveImage
                        ? "translateY(45px)"
                        : "translateY(0)",
                    }}
                  >
                    <img src="./assets/Ellipse 428.png" alt="circle2"></img>
                  </div>
                  <div
                    style={{
                      transition: "transform 0.5s ",
                      transform: moveImage
                        ? "translateY(25px)"
                        : "translateY(0)",
                    }}
                  >
                    <img src="./assets/Ellipse 429.png" alt="circle2"></img>
                  </div>
                </div>
                <div></div>
              </div>
              
              <div className="flex px-5 mt-10">
                <p className="text-lg text-white md:w-[80%] leading-8">
                Enter the  {" "}
                <span className="text-transparent bg-clip-text font-bold bg-[#FFBA00]">
                 enchanted Skill Marketplace, 
                </span>
                where your talents become treasures and your {" "}
                 <span className="text-transparent bg-clip-text bg-gradient-to-r font-bold  from-[#FFBA00] to-[#0EA4A9]">
                 D5art Passport is your map to hidden opportunities. {" "}
                  </span>
                  In this realm, biases vanish, and only the glow of your abilities guides you forward. Recruiters see your true worth, measured not by titles or backgrounds but by the brilliance of your skills.                </p>
              </div>
              
          

              <div className="w-full md:w-[100%] relative">
                <div className="flex justify-center md:justify-between px-5 h-full">
                  <NavLink to="https://ex.d5art.com/SeekerRegistration">
                    <div className="flex justify-start mt-8">
                      <button className="flex pt-3 pb-1 px-2 bg-[#1A1919] z-20 rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white">
                        <button className="p-2 rounded-full translate-y-0.5 group-hover:translate-x-[119px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5"></button>
                        <p className="-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out">
                          Create
                        </p>
                        <button className="ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                      </button>
                    </div>
                  </NavLink>
                  <div
                    className="absolute md:relative z-0"
                    style={{
                      transition: "transform 0.1s ",
                      transform: moveImage
                        ? "translateY(20px)"
                        : "translateY(0)",
                    }}
                  >
                    <img src="./assets/Ellipse 429.png" alt="circle2"></img>
                  </div>
                  <div
                    className="hidden md:block"
                    style={{
                      transition: "transform 2s ",
                      transform: moveImage
                        ? "translateY(45px)"
                        : "translateY(0)",
                    }}
                  >
                    <img src="./assets/Ellipse 428.png" alt="circle2"></img>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="w-full md:w-[100%] mt-2">
                <div className="flex justify-between px-5 h-full">
                  <div
                    style={{
                      transition: "transform 1s ",
                      transform: moveImage
                        ? "translateY(20px)"
                        : "translateY(0)",
                    }}
                  >
                    <img src="./assets/image 100.png" alt="circle2"></img>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className="lg:w-[30%] flex justify-center items-center font-nerko text-3xl">
             
                <img src="./assets/magicball.png"></img>
           
           
            </div>
           
          </div>
        </div>

        <div className="Section-5">
          <div className="w-full md:w-[100%] pt-14 px-10">
            <div className="w-[70%] flex justify-end h-full">
              <div
                className=""
                style={{
                  transition: "transform 5s ",
                  transform: moveImage
                    ? "translateY(45px) translateX(45px)"
                    : "translateY(0) translateX(0)",
                }}
              >
                <img src="./assets/image 100.png" alt="circle2" />
              </div>
            </div>
            <div>
              <p className="text-[#FFBA00] font-museomoderno font-bold text-center text-3xl">
              Skill Quest
              </p>
            </div>
          </div>
          <div className="w-full md:w-[100%] px-10 pb-10">
            <div className="flex justify-evenly h-full">
              <div
                style={{
                  transition: "transform 0.1s ",
                  transform: moveImage
                    ? "translateY(20px) translateX(20px)"
                    : "translateY(0) translateX(0)",
                }}
              >
                <img src="./assets/Ellipse 429.png" alt="circle2"></img>
              </div>
              <div className="h-full flex items-center translate-y-24">
                <img
                  style={{
                    transition: "transform 0.9s ",
                    transform: moveImage
                      ? "translateY(25px) translateX(25px)"
                      : "translateY(0) translateX(0)",
                  }}
                  className=""
                  src="./assets/Ellipse 428.png"
                  alt="circle1"
                ></img>
              </div>
            </div>
            <div className=" flex justify-center">
              <p className="text-center text-white w-[85%]">
                
Embark on a transformative journey through the Skill Quest and Knowledge Journey, mastering abilities on the Wisdom Pathway and unlocking hidden wisdom to enhance your talents and expertise. on the WEB 3 Realm
                <br />
              
              </p>
            </div>
          </div>
          <div className="flex justify-center text-white">
            <div className="w-[90%] flex flex-col gap-5 md:gap-0 md:flex-row justify-between my-10">
              {courses.slice(0, 3).map((course, index) => (
                <div
                  key={index}
                  className="md:w-[30%] p-5 grid content-between hover:bg-black/50 duration-500 rounded-2xl"
                >
                  <div>
                    <div className="flex">
                      <img
                        src={`/assets${course.image_url}`}
                        alt="Course Image"
                      />
                    </div>
                    <p className="flex justify-between mt-3">
                      <p className="font-play text-xl font-bold">
                        {course.course_name}
                      </p>
                      <span className="text-sm mt-1">
                        <i className="fa-regular fa-calendar mr-2"></i>
                        {course.duration_hours} hours
                      </span>
                    </p>
                    <p className="flex justify-between text-xs mt-3">
                      <p>
                        <i className="fa-solid fa-list-ul mr-2 text-base"></i>
                        {course.category}
                      </p>{" "}
                      <p>
                        <span className="text-[#F29D38] flex justify-center gap-1 mt-1">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                        </span>
                      </p>
                    </p>
                    <p className="text-sm my-3 text-gray-300">
                      {truncateDescription(course.course_description)}
                    </p>
                  </div>
                  <div className="flex justify-between text-sm">
                    <div className="flex">
                      <div>
                        <img src="./assets/Token.png" alt="Token Icon" />
                      </div>
                      <div>
                        <p className="mt-1.5 pl-2 font-bold">
                          $ {course.price}{" "}
                        </p>
                      </div>
                    </div>
                    <div>
                      <NavLink
                        to={`https://edu.d5art.com/precourse?id=${course.course_id}`}
                      >
                        <button className="bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]">
                          <button className="flex pt-2.5 pb-0.5 font-play px-2 bg-black rounded-full group">
                            <button className="p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[85px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                            <p className="mx-3 -translate-y-0.5 group-hover:-translate-x-4 trasnform-transition duration-500 ease-in-out text-base">
                              Enroll
                            </p>
                            <button className=" p-3 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                          </button>
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center">
            <NavLink
              className="flex justify-center relative"
              to="https://edu.d5art.com/Courses"
            >
              <button className=" text-white bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]">
                <button className="flex pt-3 pb-1 font-play px-2.5 bg-[#1A1919] rounded-full group ">
                  <button className="p-2 rounded-full translate-y-1 group-hover:translate-x-[111px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>{" "}
                  <p className="mx-3 -translate-y-0.75 group-hover:-translate-x-4 trasnform-transition duration-500 ease-in-out text-lg">
                    View All
                  </p>{" "}
                  <button className=" p-4 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                </button>
              </button>
            </NavLink>
          </div>
        </div>


    


        <div className="Section-6">
          <div className="w-full md:w-[100%] pt-14 px-10">
            <div className="w-[52%] flex justify-end  h-full">
              <div
                className=""
                style={{
                  transition: "transform 5s ",
                  transform: moveImage ? "translateY(35px)" : "translateY(0)",
                }}
              >
                <img src="./assets/Ellipse 429.png" alt="circle2"></img>
              </div>
            </div>
            <div>
              <p className="text-[#FFBA00] font-museomoderno text-center font-bold text-3xl">
                Tokenization
              </p>
            </div>
            <div className="w-[100%] flex justify-evenly h-full">
              <div
                className="-translate-y-20"
                style={{
                  transition: "transform 5s ",
                  transform: moveImage
                    ? "translateY(25px) translateX(25px)"
                    : "translateY(0) translateX(0)",
                }}
              >
                <img src="./assets/Ellipse 428.png" alt="circle2"></img>
              </div>
              <div
                className=""
                style={{
                  transition: "transform 5s ",
                  transform: moveImage
                    ? "translateY(45px) translateX(45px)"
                    : "translateY(0) translateX(0)",
                }}
              >
                <img src="./assets/image 100.png" alt="circle2"></img>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row  mt-5">
            <div className="lg:w-[50%] flex justify-center items-center ">
              <img
                src="./assets/Ellipse 7.png"
                className="absolute w-[50%]"
              ></img>
              <img
                className="w-[50%] md:w-auto"
                src="./assets/image 107.png"
              ></img>
            </div>
            <div className="lg:w-[40%] mt-8 px-5 flex items-center">
              <div>
                <p className="text-white text-center md:text-start">
                  Introducing &nbsp;
                  <span className="text-[#00768B] font-bold">D5ART Token </span>
                  the native token of D5ART. Set to launch soon, the D5ART Token
                  will offer extensive utilities. .
                </p>
                <div className="flex flex-col gap-4 mt-5">
                  <li className="text-white text-center md:text-start">
                    <span className="text-[#00768B] font-bold">
                      Skill Validation:
                    </span>
                    &nbsp; Job seekers can use the token to verify their skills
                    and experiences, enhancing the credibility of their NFT CVs.{" "}
                  </li>
                  <li className="text-white text-center md:text-start">
                    <span className="text-[#00768B] font-bold">
                      Purchase NFT CVs :
                    </span>
                    &nbsp; Job Providers can purchase NFT CVs using the token,
                    gaining access to verified candidate profiles without
                    biases.
                  </li>
                  <li className="text-white text-center md:text-start">
                    <span className="text-[#00768B] font-bold">
                      Course Enrollment:
                    </span>
                    &nbsp; Users can use tokens to enroll in courses, promoting
                    continuous skill development.{" "}
                  </li>
                  <li className="text-white text-center md:text-start">
                    <span className="text-[#00768B] font-bold">
                      Incentives for Course Completion:
                    </span>
                    &nbsp;Earn tokens upon completion of courses, which can be
                    used for future enrollments or redeemed for rewards.{" "}
                  </li>
                </div>
                <div className="flex md:justify-start justify-center mt-10 md:mt-20">
                  <NavLink>
                    <button
                      disabled
                      className="flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white"
                    >
                      <button
                        disabled
                        className="p-2 rounded-full translate-y-0.5 group-hover:translate-x-[176px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5"
                      ></button>{" "}
                      <p className="-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out">
                        Coming Soon
                      </p>{" "}
                      <button
                        disabled
                        className="ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"
                      ></button>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="Section-7">
          <div className=" md:h-full w-full mt-12 md:mt-24 ">
            <p className="text-[#FFBA00] font-museomoderno text-center font-bold text-3xl">
              Stay Updated
            </p>
            <div className="stay-updated ">
              <div className="px-10 md:px-28 py-10 pt-12 w-full flex flex-col md:flex-row md:justify-between gap-3 md:gap-7">
                <div
                  onClick={() =>
                    handleBlog(
                      "https://medium.com/@FABCLLC/how-to-deploy-a-smart-contract-on-ton-network-f99bf728934a"
                    )
                  }
                  className={`cursor-pointer group h-[10rem] md:h-[27.5rem] flex items-end py-5 rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${
                    activeIndex === 0
                      ? "md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]"
                      : "opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0"
                  }`}
                  style={{
                    backgroundImage:
                      "url('./assets/1_76R-dyhg_Md21Lr83RREfQ.webp')",
                  }}
                >
                  <p
                    className={`duration-500 ${
                      activeIndex === 0 ? "" : "hidden"
                    } bg-black bg-opacity-70 text-center text-white text-sm md:text-base py-2 md:py-5 w-full`}
                  >
                    How to Deploy a Smart contract on TON Network?
                  </p>
                </div>
                <div
                  onClick={() =>
                    handleBlog(
                      "https://medium.com/@FABCLLC/build-your-own-custom-blockchain-using-polygon-supernet-e71bd75afe70"
                    )
                  }
                  className={`cursor-pointer group h-[10rem] md:h-[27.5rem] flex items-end py-5 rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${
                    activeIndex === 1
                      ? "md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]"
                      : "opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0"
                  }`}
                  style={{ backgroundImage: "url('./assets/1_23.gif')" }}
                >
<p
                    className={` duration-500 ${
                      activeIndex === 1 ? "" : "hidden"
                    } bg-black bg-opacity-70 text-center text-white text-sm md:text-base py-2 md:py-5 w-full`}
                  >                    Build Your Own Custom Blockchain Using Polygon Supernet
                  </p>
                </div>
                <div
                  onClick={() =>
                    handleBlog(
                      "https://medium.com/@FABCLLC/polygon-the-next-generation-of-blockchain-f75bb217ace0"
                    )
                  }
                  className={`cursor-pointer group h-[10rem] md:h-[27.5rem] flex items-end py-5 rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${
                    activeIndex === 2
                      ? "md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]"
                      : "opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0"
                  }`}
                  style={{
                    backgroundImage:
                      "url('./assets/1_zcczsArZudz3kD2mVfoOoQ.webp')",
                  }}
                >
<p
                    className={` duration-500 ${
                      activeIndex === 2 ? "" : "hidden"
                    } bg-black bg-opacity-70 text-center text-white text-sm md:text-base py-2 md:py-5 w-full`}
                  >                    Polygon: The Next Generation of Blockchain.
                  </p>
                </div>
                <div
                  onClick={() =>
                    handleBlog(
                      "https://medium.com/@FABCLLC/enhancing-trust-and-transparency-polygon-id-steps-to-create-an-organization-issuer-node-f997b32b3d1b"
                    )
                  }
                  className={`cursor-pointer group h-[10rem] md:h-[27.5rem] flex items-end py-5 rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${
                    activeIndex === 3
                      ? "md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]"
                      : "opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0"
                  }`}
                  style={{ backgroundImage: "url('./assets/polygon_id.gif')" }}
                >
<p
                    className={`duration-500 ${
                      activeIndex === 3 ? "" : "hidden"
                    } bg-black bg-opacity-70 text-center text-white text-sm md:text-base py-2 md:py-5 w-full`}
                  >     Enhancing Trust and Transparency, Polygon ID:
                  </p>
                </div>
              </div>
            </div>
            <p className="text-[#FFFFFF]/75 mx-auto text-center w-[80%] font-museomoderno">
              Keep yourself updated with insights into the latest news, trends,
              tips and best practices in recruitment, career development and
              Blockchain Technology.{" "}
            </p>
          </div>
        </div>

        <div className="Section-8 ">
          <div className="flex justify-center">
            <div className="w-full  md:w-[50%] pb-16 px-10">
              <div className="flex justify-center">
                <div
                  className=""
                  style={{
                    transition: "transform 2s ",
                    transform: moveImage ? "translateY(45px)" : "translateY(0)",
                  }}
                >
                  <img src="./assets/image 100.png" alt="circle2"></img>
                </div>
              </div>
              <div className="flex justify-around h-full">
                <div className="h-full flex items-center ">
                  <img
                    style={{
                      transition: "transform 0.9s ",
                      transform: moveImage
                        ? "translateY(25px)"
                        : "translateY(0)",
                    }}
                    className=""
                    src="./assets/Ellipse 428.png"
                    alt="circle1"
                  ></img>
                </div>

                <div
                  style={{
                    transition: "transform 0.1s ",
                    transform: moveImage ? "translateY(20px)" : "translateY(0)",
                  }}
                >
                  <img src="./assets/Ellipse 429.png" alt="circle2"></img>
                </div>
              </div>
              <div className="-translate-y-20">
                <p className="text-[#FFBA00] font-museomoderno text-center font-bold text-3xl">
                  Testimonials
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5 pb-10">
            <div className="w-[100%] flex flex-col gap-12 lg:gap-0 lg:flex-row justify-around px-10">
              <div
                className="lg:w-[45%] grid content-between  bg-[#1e1e1e] border-t-2 border-gray-600"
                style={{ borderRadius: "25px" }}
              >
                <div className="-translate-y-10 w-[5.5rem] h-[5.5rem] rounded-full overflow-hidden">
                  <img src="./assets/image 90.png"></img>
                </div>
                <div className="px-3">
                  <p className="text-white font-poppins text-sm">
                    Cracking Blockchain gave me the foundational knowledge I
                    needed. I landed a job as a Blockchain Developer within two
                    months of completing it.
                  </p>
                </div>
                <div className="flex justify-between items-center  px-1 mt-2">
                  <div
                    className="px-4  bg-contain bg-no-repeat py-5 md:w-[45%]"
                    style={{
                      backgroundImage: "url('./assets/Vectorcolon.png')",
                      borderRadius: "25px",
                    }}
                  >
                    <p className="  text-[#FFBA00] text-xl">
                      <span className="text-[#00768B] font-bold">
                        {" "}
                        NFT ID :
                      </span>{" "}
                      0x8511a
                    </p>
                    <p className="text-white ">Blockchain Analyst</p>
                  </div>
                </div>
              </div>
              <div
                className="lg:w-[45%] grid content-between bg-[#1e1e1e] border-t-2 border-gray-600"
                style={{ borderRadius: "25px" }}
              >
                <div className="-translate-y-10 w-[5.5rem] h-[5.5rem] rounded-full overflow-hidden">
                  <img src="./assets/image 3yr.png"></img>
                </div>
                <div className="px-3 text-white font-poppins text-sm">
                  <p>
                    Cracking Blockchain was the perfect starting point. Now, I'm
                    working as a Blockchain Analyst.
                  </p>
                </div>
                <div className="flex justify-between items-center  px-1 mt-2">
                  <div
                    className="px-4  bg-contain bg-no-repeat py-5 md:w-[45%]"
                    style={{
                      backgroundImage: "url('./assets/Vectorcolon.png')",
                      borderRadius: "25px",
                    }}
                  >
                    <p className="  text-[#FFBA00] text-xl">
                      <span className="text-[#00768B] font-bold">
                        {" "}
                        NFT ID :
                      </span>{" "}
                      0xd2827
                    </p>
                    <p className="text-white ">Smartcontract Developer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-14">
            <div className="w-[100%] flex flex-col gap-12 lg:gap-0  lg:flex-row justify-around px-10">
              <div
                className="lg:w-[45%] grid content-between bg-[#1e1e1e] border-t-2 border-gray-600"
                style={{ borderRadius: "25px" }}
              >
                <div className="-translate-y-10 w-[5.5rem] h-[5.5rem] rounded-full overflow-hidden">
                  <img src="./assets/image 90 (1).png"></img>
                </div>
                <div className="px-3">
                  <p className="text-white font-poppins text-sm">
                    The Blockchain and MetaMask course advanced my career. I’m
                    now proud to work as a Blockchain Integration Specialist.
                  </p>
                </div>
                <div className="flex justify-between items-center  px-1 mt-2">
                  <div
                    className="px-4  bg-contain bg-no-repeat py-5 md:w-[45%]"
                    style={{
                      backgroundImage: "url('./assets/Vectorcolon.png')",
                      borderRadius: "25px",
                    }}
                  >
                    <p className="  text-[#FFBA00] text-xl">
                      <span className="text-[#00768B] font-bold">
                        {" "}
                        NFT ID :
                      </span>{" "}
                      0x23912
                    </p>
                    <p className="text-white ">Blockchain Developer</p>
                  </div>
                </div>
              </div>
              <div
                className="lg:w-[45%] grid content-between bg-[#1e1e1e] border-t-2 border-gray-600"
                style={{ borderRadius: "25px" }}
              >
                <div className="-translate-y-10 w-[5.5rem] h-[5.5rem] rounded-full overflow-hidden">
                  <img src="./assets/image 90.png"></img>
                </div>
                <div className="px-3">
                  <p className="text-white font-poppins text-sm">
                    Cracking Blockchain exceeded my expectations. It helped me
                    become a Smart Contract Developer.
                  </p>
                </div>
                <div className="flex justify-between items-center  px-1 mt-2">
                  <div
                    className="px-4  bg-contain bg-no-repeat py-5 md:w-[45%]"
                    style={{
                      backgroundImage: "url('./assets/Vectorcolon.png')",
                      borderRadius: "25px",
                    }}
                  >
                    <p className="  text-[#FFBA00] text-xl">
                      <span className="text-[#00768B] font-bold">
                        {" "}
                        NFT ID :
                      </span>{" "}
                      0x41496
                    </p>
                    <p className="text-white ">Smartcontract Developer </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="Section-9">
          <div className="Council-of-SME py-10 mt-16">
            <div className="md:flex h-screen overflow-hidden ">
              <div className="md:w-[40%] flex justify-center">
                <div className="rotate-45">
                  <div
                    className=""
                    style={{
                      transition: "transform 1s ease-in-out",
                      transform: moveImage
                        ? "translatey(50px)"
                        : "translatey(0)",
                    }}
                  >
                    <img src="./assets/image 100.png" alt="circle2"></img>
                  </div>
                </div>
              </div>

              <div className="absolute flex items-center justify-center md:h-full w-full md:pt-20 text-white ">
                <div className="flex justify-center ">
                  <div className="w-[90%] md:w-[85%]">
                    <p className="font-museomoderno text-4xl mt-10 text-center text-[#FFBA00] font-bold">
                      Council of{" "}
                      <span
                        style={{
                          backgroundClip: "text",
                          WebkitBackgroundClip: "text",
                          color: "transparent",
                          backgroundImage:
                            "linear-gradient(to right, #0EA4A9, #0EA4A9, #F29D38)",
                        }}
                      >
                        SME
                      </span>
                    </p>
                    <p className="py-5 md:py-10 text-lg text-center font-museomoderno">
                      At FABC, we're proud to collaborate with a diverse network
                      of global Consortium partners who share our commitment to
                      innovation and excellence.
                    </p>
                    <div className="hidden md:block">
                      <div className="flex justify-around mt-1 items-center">
                        <div className="p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid gap-3">
                          <div className="flex justify-center">
                            <div>
                              <img
                                src="./assets/sakthi_prev_ui 1.png"
                                className="rounded-3xl  border-2  border-[#008080]"
                              ></img>
                            </div>
                          </div>
                          <p className="font-black text-center font-poppins text-[#0087a5] text-lg">
                            Sakthi Visakan Rajaguru
                          </p>
                          <p className="font-black text-center font-play text-lg">
                            CEO, FABC
                          </p>
                        </div>
                        <div className="p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3">
                          <div className="flex justify-center">
                            <div>
                              <img
                                src="./assets/Khalid_prev_ui 1.png"
                                className="rounded-3xl  border-2  border-[#008080]"
                              ></img>
                            </div>
                          </div>
                          <p className="font-black text-center font-poppins text-[#0087a5] text-lg">
                            Khalid Al Mansouri
                          </p>
                          <p className="font-black text-center font-play text-lg uppercase">
                            Board Advisor
                          </p>
                        </div>
                        <div className="p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3">
                          <div className="flex justify-center">
                            <div>
                              <img
                                src="./assets/Anup BM_prev_ui 1.png"
                                className="rounded-3xl w-10/12  border-2  border-[#008080]"
                              ></img>
                            </div>
                          </div>
                          <p className="font-black text-center font-poppins text-[#0087a5] text-lg">
                            Anup Kumar
                          </p>
                          <p className="font-black text-center font-play text-lg">
                            CTO
                          </p>
                        </div>
                        <div className="hidden p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3">
                          <div className="flex justify-center">
                            <div>
                              <img
                                src="./assets/adam.png"
                                className="rounded-3xl"
                              ></img>
                            </div>
                          </div>
                          <p className="font-black text-center font-poppins text-[#0087a5] text-lg">
                            Adam Roosevelt
                          </p>
                          <p className="font-black text-center font-play text-lg">
                            SUBJECT MATTER EXPERT
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-around pb-3 items-center">
                        <div className="p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid gap-3">
                          <div className="flex justify-center">
                            <div>
                              <img
                                src="./assets/Annamalai BM_prev_ui 1.png"
                                className="rounded-3xl  border-2  border-[#008080]"
                              ></img>
                            </div>
                          </div>
                          <p className="font-black text-center font-poppins text-[#0087a5] text-lg">
                            Annamalai Palaniappan
                          </p>
                          <p className="font-black text-center font-play text-lg">
                            COO
                          </p>
                        </div>
                        <div className="p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3">
                          <div className="flex justify-center">
                            <div>
                              <img
                                src="./assets/Gauvrav_prev_ui 1.png"
                                className="rounded-3xl  border-2  border-[#008080]"
                              ></img>
                            </div>
                          </div>
                          <p className="font-black text-center font-poppins text-[#0087a5] text-lg">
                            Gaurav Sharma
                          </p>
                          <p className="font-black text-center font-play text-lg uppercase">
                            CFO
                          </p>
                        </div>
                        <div className="p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3">
                          <div className="flex justify-center">
                            <div>
                              <img
                                src="./assets/alun-_prev_ui 1.png"
                                className="rounded-3xl w-11/12 border-2  border-[#008080]"
                              ></img>
                            </div>
                          </div>
                          <p className="font-black text-center font-poppins text-[#0087a5] text-lg">
                            Alun Richards
                          </p>
                          <p className="font-black text-center font-play text-lg uppercase">
                            Board Advisor
                          </p>
                        </div>
                        <div className="hidden p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3">
                          <div className="flex justify-center">
                            <div>
                              <img
                                src="./assets/adam.png"
                                className="rounded-3xl"
                              ></img>
                            </div>
                          </div>
                          <p className="font-black text-center font-poppins text-[#0087a5] text-lg">
                            Adam Roosevelt
                          </p>
                          <p className="font-black text-center font-play text-lg">
                            SUBJECT MATTER EXPERT
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="block md:hidden ">
                      <div className="flex justify-between mt-5">
                        <button onClick={goToPrev}>
                          <i class="fa-solid fa-chevron-left px-3 py-2 rounded-full bg-white/10"></i>
                        </button>
                        <div className="p-5 w-[70%] rounded-3xl bg-white/5 grid gap-3">
                          <div className="flex justify-center">
                            <div>
                              <img
                                src={teamMembers[currentIndex].imageSrc}
                                className="rounded-3xl"
                                alt={teamMembers[currentIndex].name}
                              />
                            </div>
                          </div>
                          <p className="font-black text-center font-poppins text-[#0087a5] text-lg">
                            {teamMembers[currentIndex].name}
                          </p>
                          <p className="font-black text-center font-play text-lg">
                            {teamMembers[currentIndex].role}
                          </p>
                        </div>
                        <button onClick={goToNext}>
                          <i class="fa-solid fa-chevron-right  px-3 py-2 rounded-full bg-white/10"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="Section-10 mt-5">
          <div className="flex justify-center">
            <div className="w-[100%] px-10">
              <div className="flex justify-center">
                <div
                  className="-z-10 "
                  style={{
                    transition: "transform 2s ",
                    transform: moveImage ? "translateY(45px)" : "translateY(0)",
                  }}
                >
                  <img src="./assets/image 100.png" alt="circle2"></img>
                </div>
              </div>
              <div className="flex justify-around h-full">
                <div className="h-full flex items-center ">
                  <img
                    style={{
                      transition: "transform 0.9s ",
                      transform: moveImage
                        ? "translateY(25px)"
                        : "translateY(0)",
                    }}
                    className=""
                    src="./assets/Ellipse 428.png"
                    alt="circle1"
                  ></img>
                </div>

                <div
                  style={{
                    transition: "transform 0.9s ",
                    transform: moveImage
                      ? "translateY(20px) translateX(20px)"
                      : "translateY(0) translateX(0px)",
                  }}
                >
                  <img src="./assets/Ellipse 429.png" alt="circle2"></img>
                </div>
              </div>
              <div className="-translate-y-20">
                <p className="text-[#FFBA00] font-museomoderno text-center font-bold text-3xl">
                  Reach us
                </p>
              </div>
            </div>
          </div>
          <p className="py-5  text-white text-lg text-center font-museomoderno w-[80%] mx-auto">
            Join us to enhance your career with cutting-edge blockchain
            technology. Access NFT CVs, exclusive courses, and industry
            insights.{" "}
          </p>
          <div className="pb-20 pt-10 w-[90%] mx-auto flex flex-col md:flex-row justify-center gap-5">
            <div
              className="md:w-[60%] bg-[#D9D9D90F] text-gray-300 rounded-2xl p-10"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col md:flex-row justify-center gap-10">
                  <div className="md:w-[50%] flex flex-col gap-10">
                    <div className="flex flex-col">
                      <label className="text-sm 2xl:text-lg">First Name</label>
                      <input
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleSubmitChange}
                        className="bg-transparent border-b outline-none w-[100%]"
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="text-sm 2xl:text-lg">Email</label>
                      <input
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleSubmitChange}
                        className="bg-transparent border-b outline-none w-[100%]"
                        required
                      />
                    </div>
                  </div>
                  <div className="md:w-[50%] flex flex-col gap-10">
                    <div className="flex flex-col">
                      <label className="text-sm 2xl:text-lg">Last Name</label>
                      <input
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleSubmitChange}
                        className="bg-transparent border-b outline-none w-[100%]"
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="text-sm 2xl:text-lg">Phone</label>
                      <input
                        name="phone"
                        value={formData.phone}
                        onChange={handleSubmitChange}
                        className="bg-transparent border-b outline-none w-[100%]"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-10">
                  <label className="text-sm 2xl:text-lg">Message</label>
                  <input
                    name="message"
                    value={formData.message}
                    onChange={handleSubmitChange}
                    className="bg-transparent placeholder:text-sm py-1 border-b outline-none w-[100%]"
                    placeholder="Write your message..."
                    required
                  />
                </div>
                <div className="mt-10 md:pt-20 pb-5 flex justify-center">
                  <button
                    type="submit"
                    className="text-white bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]"
                  >
                    <div className="flex pt-3 pb-1 font-play px-2.5 bg-[#09090B] rounded-full group">
                      <div className=" w-5 h-5 rounded-full translate-y-1 group-hover:translate-x-[110px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></div>
                      <p className="mx-3 -translate-y-0.75 group-hover:-translate-x-4 transform-transition duration-500 ease-in-out text-lg">
                        Submit
                      </p>
                      <div className="p-4 rounded-full -translate-y-1 border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></div>
                    </div>
                  </button>
                </div>
              </form>
            </div>
            <div className="md:w-[40%]">
              <div>
                <img src="/assets/image 114.png"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
