import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Landing from './Components/Landing';
import Header from './Components/Header';
import Faq from './Components/Faq';
import Footer from './Components/Footer';

function Routers() {
    return (
      <Routes>
      <Route path="/" element={<Landing/>} />
      <Route path="/Header" element={<Header/>} />
      <Route path="/footer" element={<Footer/>} />

      <Route path="/Faq" element={<Faq/>} />
       </Routes>
  );
}

export default Routers;